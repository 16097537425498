<template>
  <template v-if="src?.size">
    <picture
      :class="customClass ? customClass : ''"
      v-if="$device.isDesktop"
    >
      <source
        type="image/webp"
        :srcset="
          `${src.size.size3_webp ? src.size.size3_webp : src.url}` +
          ' 1x, ' +
          `${src.size.size2_webp ? src.size.size2_webp : src.url}` +
          ' 2x'
        "
      />
      <img
        :class="['img-cover', customImgClass ? customImgClass : '']"
        :alt="alt"
        :src="src.url"
        :srcset="
          `${src.size.size3 ? src.size.size3 : src.url}` +
          ' 1x, ' +
          `${src.size.size2 ? src.size.size2 : src.url}` +
          ' 2x'
        "
      />
    </picture>
    <picture :class="customClass ? customClass : ''" v-else>
      <source
        type="image/webp"
        :srcset="`${src.size.size2_webp ? src.size.size2_webp : src.url}`"
      />
      <img
        :class="['img-cover', customImgClass ? customImgClass : '']"
        :alt="alt"
        :src="src.url"
        :srcset="`${src.size.size2 ? src.size.size2 : src.url}`"
      />
    </picture>
  </template>
  <template v-else>
    <img
      :class="['img-cover', customImgClass ? customImgClass : '']"
      :src="src?.url"
      :alt="alt"
    />
  </template>
</template>

<script>
export default {
  name: 'BaseResponsiveImagesTripCard',
  props: ['src', 'alt', 'customClass', 'customImgClass'],
}
</script>

<style lang="scss" scoped></style>
