<template>
  <div
    v-if="travellerReview"
    class="relative"
    @mouseover="openTooltip"
    @mouseleave="showToolTip = false"
  >
    <div class='flex items-center hand' @click='goToProfile()'>
<!--    <router-link-->
<!--      class="flex items-center hand"-->
<!--      :to="{-->
<!--        name: 'local-designer',-->
<!--        params: { name: travellerReview.profile },-->
<!--      }"-->
<!--      :target="target"-->
<!--    >-->
      <span
        class="w-25 h-25 br-p-50 bd-2 bd-white dib overflow-hidden mr-8"
        v-if="travellerReview.avatar.url"
      >
        <!--        <ResponsiveImages v-if="travellerReview.avatar.size" :src='travellerReview'></ResponsiveImages>
        <img v-else class="img-cover" :src="travellerReview.avatar.url"-->
        <BaseResponsiveImages
          :src="travellerReview.avatar"
          :alt="travellerReview.name"
        />
        </span>
      <!--      <span v-if="travellerReview.name" class="dib fs-12 fw-700 mr-13">{{
        travellerReview.name
      }}</span>-->
      <span v-if="travellerReview.rating" class="dib">
        <!--        <span class="yellow fs-16 mr-4">&starf;</span>-->
        <span class="mr-4">
          <img width='14' height='14' alt='Star rating' src="https://s3-cdn.designerjourneys.com/static/icons/icon-rating.svg" />
        </span>
        <span class="fs-16 lh-16 white">{{ travellerReview.rating }}</span>
      </span>
<!--    </router-link>-->
    </div>
    <transition name="tooltip-fade">
      <ToolTip
        :content="travellerReview.name"
        v-if="showToolTip"
        position="top"
        :customClass="'tooltip-top-left pb-9 nleft-8'"
      ></ToolTip>
    </transition>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import ToolTip from '~/components/ui/tooltip/ToolTip'
import BaseResponsiveImages from '~/components/ui/BaseResponsiveImages/BaseResponsiveImages'

export default {
  name: 'TravellerReview',
  components: { BaseResponsiveImages, ToolTip },
  props: {
    travellerReview: { type: Object, default: null },
    target: { type: String, default: '_self' },
    viewOnly: { type: Boolean, default: true }
  },
  setup(props) {
    const router = useRouter()
    const showToolTip = ref(false)

    const goToProfile = () => {
      if (props.viewOnly) {
        return;
      }
      const { href } = router.resolve({
        name: 'local-designer',
        params: { name: props.travellerReview.profile },
      })
      window.open(href, '_blank')
    }

    const openTooltip = () => {
      if (props.travellerReview.name) showToolTip.value = true
    }

    return { goToProfile, showToolTip, openTooltip }
  },
}
</script>

<style scoped></style>
